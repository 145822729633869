import React from 'react'
import login from '../images/fie.png'

const Login_Form = () => {
  return (
    <div className='flex h-screen'>
      <div className='hidden sm:block flex-1'>
        <img className='object-cover w-full h-full' src={login} alt='login' />
      </div>
      <div className='flex-1 bg-purple-700 flex flex-col justify-center px-5'>
        <form className='max-w-[500px] w-full mx-auto bg-gray-300 p-8 rounded-lg'>
          <h2 className='text-4xl dark:text-white text-purple-700 font-bold text-center'>Sign In</h2>
          <div className='flex flex-col text-black py-2'>
            <label htmlFor='email'>E-mail</label>
            <input id='email' className='rounded-lg bg-white mt-2 p-2 focus:border-blue-500 focus:bg-white focus:outline-none' type='text'></input>
          </div>
          <div className='flex flex-col text-black py-2'>
            <label htmlFor='password'>Password</label>
            <input id='password' className='rounded-lg bg-white mt-2 p-2 focus:border-blue-500 focus:bg-white focus:outline-none' type='password'></input>
          </div>
          <div className='flex justify-between text-gray-400 py-2'>
            <label className='flex items-center' htmlFor='remember'>
              <input id='remember' className='mr-2' type='checkbox'></input>Remember Me
            </label>
            <p>Forgot Password?</p>
          </div>
          <button className='w-full my-5 py-2 bg-purple-500 shadow-lg shadow-purple-500/50 hover:shadow-purple-500/40  text-white font-semibold rounded-lg'>Sign In</button>
        </form>
        <div className='text-center text-gray-400 py-4'>
          Don't have an account yet? <button className='text-purple-500'>Sign Up</button>
        </div>
      </div>
    </div>
  )
}

export default Login_Form
